export const environment = {
  production: false,
  cloud: 'dev',
  bossApiBaseUrl: 'https://portal-mift.mitellabs.com:8443',
  mediaTranscodeApiBaseUrl: 'https://media.dev.api.mitel.io/2017-09-01',
  clientId: 'a82d70fb2efafafe54c70eed9cb46164',
  packetTrace: true,
  thisFilesName: 'environment.qa.ts',

  /**
    * This is the UAT Live Agent Salesforce script
  */
  salesForceURL: 'https://c.la1-c1cs-phx.salesforceliveagent.com/content/g/js/46.0/deployment.js',
  /**
  * this id will be used to establish a connection with launchdarkly
  */
  featureFlagClientId: '60b553567cc3940de06f275f'
};

